import React, { Component,useState } from 'react';
import './index.css';
import Header from '@components/header';
import scan from '@img/scan.png';

const rdom = require('react-dom');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      height: window.innerHeight,
      index: 1
    }
  }
  
  componentDidMount() {
    // 注册浏览器尺寸变化监听事件， 刷新桌面尺寸
    window.addEventListener('resize', this.handleSize);
  }

  // 自适应浏览器的高度
  handleSize = () => {
    this.setState({
      height:window.innerHeight,
    });
  }

  changeBg(index) {
    this.setState({
      index,
      imgBg:`../resource/img/fullPage${index}.png`
    })
  }

  handleScroll(e) {
    const ele = rdom.findDOMNode(this);
    const { index } = this.state;
    if (e.nativeEvent.deltaY <= 0) {
      /* scrolling up */
      if(ele.scrollTop <= 0) {
        //e.preventDefault();
        if(index>1) {
          this.setState({index:index-1})
        }
      }
    } else{
      /* scrolling down */
      if(ele.scrollTop + ele.clientHeight >= ele.scrollHeight) {
        //e.preventDefault();
        if(index<3) {
          this.setState({index:index+1})
        }
      }
    }
  }

  render() { 
    const { index,height } = this.state;
    var style ={
      bgd:{
        backgroundImage: index===1?("url("+require("@img/fullPage1.png")+")"):(index===2?("url("+require("@img/fullPage2.png")+")"):("url("+require("@img/fullPage3.png")+")")),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height:height,
        backgroundPosition: "center top"
      }
    }
    return (
      <div className="main" onWheel={(e) => this.handleScroll(e)}>
        <div className="wrap">
          <Header />
          <div className="container">
            <div className="m_contents">
              <div className="fullpage-wrapper" >
                <div className="slide" 
                  style={style.bgd}>
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="inner">
              <div className="shopinfo">
                <img src={scan} />
                <p className="more">扫一扫关注俪泊兰公众号联系我们</p>
              </div>
              <div className="xans-layout-footer">
                <ul>
                  <p className="address">
                    <span>
                      公司：Leblanc＆CoLtd.首席执行官：Jong-Hoon Jeong READY许可证号：342-81-00558 
                    </span>
                    <span>
                      地址：首尔市永登浦区汝ou岛洞26号，邮政编码3203（LAB：首尔市锦川区加山洞319-8 Ace汉索大厦714）
                    </span>
                  </p>
                  <p className="copyright">
                    copyright © 2020 LEBLANC版权所有。
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        <div className="fp-nav">
          <ul>
            <li>
              <a className={index===1?"active":""} 
                onClick={()=>this.changeBg(1)}>
                <span></span>
              </a>
            </li>
            <li>
              <a className={index===2?"active":""} 
                onClick={()=>this.changeBg(2)}>
                <span></span>
              </a>
            </li>
            <li>
              <a className={index===3?"active":""} 
                onClick={()=>this.changeBg(3)}>
                <span></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default Home;
