import React, { Component } from 'react';
import './index.less';
import scan from '@img/scan.png';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className="footers">
        <div className="leftText">
          {
            // <p>营业时间。周一至周五 上午10点至晚上7点。关闭，星期六星期日假期</p>
            // <p>电子邮件。leblanc@leblancnco.co.kr电话。02-786-4006</p>
          }
          <img src={scan} />
          <p>扫一扫关注公众号了解更多</p>
        </div>
        <div className="rightText">
          <p>公司：Leblanc＆Co.，Ltd.首席执行官：Jong-Hoon Jeong 邮购：READY许可证号：342-81-00558</p>
          <p>地址：首尔市永登浦区汝ou岛洞26号，邮政编码3203（LAB：首尔市锦川区加山洞319-8 Ace汉索大厦714）</p>
          <p>版权©2020 LEBLANC。版权所有。</p>
        </div>
      </div>
    );
  }
}
 
export default Footer;