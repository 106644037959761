import React, { Component } from 'react';
import './index.less';
import Header from '@components/header';
import rndteam from '@img/rndteam.png';
import Footer from '@components/footer';

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className="team">
        <Header />
        <div className="container">
          <img src={rndteam}/>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default Team;