import React, { Component } from 'react';
import './index.less';
import Header from '@components/header';
import brandvalue from '@img/brandvalue.png';
import Footer from '@components/footer';

class BrandValue extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className="brandValue">
        <Header />
        <div className="container">
          <img src={brandvalue}/>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default BrandValue;