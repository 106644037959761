import React, { Component } from 'react';
import './index.less';
import Header from '@components/header';
import Footer from '@components/footer';
import lbcImg1 from '@img/lbcImg1.png';
import lbcImg2 from '@img/lbcImg2.png';
import lbcImg3 from '@img/lbcImg3.png';
import lbcImg4 from '@img/lbcImg4.png';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      shopList: [
        {
          id:1,
          name:"俪泊兰水光面膜两部曲",
          price: 188,
          img: lbcImg1
        },
        {
          id:2,
          name:"俪泊兰海洋矿物™舒缓面膜",
          price: 128,
          img: lbcImg2
        },
        {
          id:3,
          name:"俪泊兰海洋精萃™修护营养面膜两部曲",
          price: 198,
          img: lbcImg3
        },
        {
          id:4,
          name:"俪泊兰清洁洁面乳",
          price: 201,
          img: lbcImg4
        }
      ]
    }
  }
  render() { 
    const {shopList} = this.state;
    return ( 
      <div className="product">
        <Header />
        <div className="container">
          <div className="title">产品</div>
          <div className="shopList">
            {
              shopList.map((item,index)=>(
                <div className="singleShop" key={index}>
                  <div className="shopDetail">
                    <img src={item.img} alt=""/>
                    <p>{item.name}</p>
                    <p>零售价￥{item.price}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Product;
