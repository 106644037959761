import React, { Component } from 'react';
import "./index.css";
import logo from '@img/logo.png';
import { Link } from 'react-router-dom';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className="header">
        <div className="topArea">
          <div className="top_wrap">
            <div className="top_logo">
              <Link className="opacity" to="/">
                <img src={logo} />
              </Link>
            </div>
            <div className="nav">
              <ul className="menu">
                <li className="menu_li">
                  <Link to="/company">关于</Link>
                </li>
                <li>
                  <Link to="/brandValue">品牌价值</Link>
                </li>
                <li>
                  <Link to="/product">产品</Link>
                </li>
                <li>
                  <Link to="/history">历史</Link>
                </li>
                <li>
                  <Link to="/team">研发团队</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Header;