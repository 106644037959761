import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.less';
import Home from "@pages/home";
import BrandValue from "@pages/brandValue";
import Product from "@pages/product";
import Company from "@pages/company";
import Team from "@pages/team";
import History from "@pages/history";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/brandValue" component={BrandValue} />
      <Route exact path="/product" component={Product} />
      <Route exact path="/company" component={Company} />
      <Route exact path="/team" component={Team} />
      <Route exact path="/history" component={History} />
    </Switch>
    
  );
}

export default App;
