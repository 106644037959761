import React, { Component } from 'react';
import './index.less';
import Header from '@components/header';
import Footer from '@components/footer';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    var style ={
      bgd:{
        backgroundImage: "url("+require("@img/company.png")+")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "1120px",
        width:"100%",
        backgroundPosition: "center top"
      }
    }
    return ( 
      <div className="company">
        <Header />
        <div className="bcImg" style={style.bgd}/>
        <div className="container">
          <div className="part">
            <p>LBC(俪泊兰)是白色纯洁(Leblanc), 闪耀(Brilliance),</p>
            <p>化妆品(Cosmetics)的合成语, </p>
          </div>
          <div className="part">
            <p>品牌致力于追溯女性本源之美，焕启肌肤耀白光采。</p>
          </div>
          <div className="part">
            <p>韩国独特的医疗美容技术吸引了世界各地的关注</p>
            <p>它是与遍布世界各地的特殊成分和谐创造的。</p>
          </div>
          <div className="part">
            <p>俪泊兰的独特策展，无止境的探索，无止境的创新</p>
            <p>精致是我们一直追求的品牌概念。</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default Company;